<template>
  <div class="error404">
    404
  </div>
</template>

<script>
export default {
};
</script>

<style scoped>
.error404 {
  height: 100%;
  display: flex;
  justify-content: center;
  font-size: 7em;
  position: fixed;
  align-items: center;
  width: 100%;
}
</style>